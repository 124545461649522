.ql-editor {
	-webkit-user-select: text;
}
.ql-clipboard {
	-webkit-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
}

/** Lightbox library toolbar height is not needed */
.ril__toolbar {
	height: 0 !important;
}